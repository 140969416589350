
<div class="navbar">
  <div class="navbar-left">
    <div class="logo" (click)=" navigateHome()">
      <img src="../../assets/images/tlobby-logo.png" alt="Teacher's Lobby Logo">
    </div>
    <div class="menu" (click)="toggleMenu()">
      <div class="menu-bars"></div>
      <div class="menu-bars"></div>
      <div class="menu-bars"></div>
    </div>
  </div>
  <div class="navbar-right">
    <ul class="nav-items">
      <li class="nav-item">
        <a href="/about">About</a>
      </li>
      <li class="nav-item">
        <a href="/mission">Our Mission</a>
      </li>
      <li class="nav-item">
        <a href="/blog">Blog</a>
      </li>
      <li class="nav-item">
        <a href="/participate">Participate</a>
      </li>
      <li class="nav-item btn login" (click)="navigateToLogin()">Login</li>
      <li class="nav-item btn register" (click)="navigateToRegister()">Register</li>
    </ul>
  </div>
</div>
