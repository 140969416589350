import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  show: true;
  hide: false;

  showHelpAFriend: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  openHelpFriend() {
    this.showHelpAFriend ? !this.showHelpAFriend : this.showHelpAFriend;
  }

}
