import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-participate',
  templateUrl: './participate.component.html',
  styleUrls: ['./participate.component.scss']
})
export class ParticipateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
