<!-- <<p>home-page works!</p> -->
<div class="page-container">
  <div class="left-container">
    <div class="drawer-1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z"/></svg>
    </div>
    <div class="drawer-2">
      <div class="teacher-store-btn btn">
        <a href="https://codeastrology.myshopify.com/collections/zodiac-hoodies">Teacher's Store</a>
      </div>
      <div class="desktop-hide">
        <div class="nav-icon">&#10132;</div>
        <span>Shop</span>
      </div>
    </div>
    <div class="drawer-3">
      <div class="teacher-store-btn btn">
        <a href="https://codeastrology.myshopify.com/collections/zodiac-hoodies">Self Assessment</a>
      </div>
      <div class="desktop-hide">
        <div class="nav-icon">&#x263B;</div>
        <span>Health</span>
      </div>
    </div>
    <div class="drawer-4">
      <div class="teacher-store-btn btn">
        <a href="https://codeastrology.myshopify.com/collections/zodiac-hoodies">Professional Development</a>
      </div>
      <div class="desktop-hide">
        <div class="nav-icon">&#9998;</div>
        <span>Train</span>
      </div>
    </div>
    <div class="drawer-5">
      <p>How can I help a friend today?</p>
      <div class="desktop-hide">
        <div class="nav-icon">&#10084;</div>
        <span>Help</span>
      </div>
    </div>
    <div class="drawer-6">
      <p>Ask a friend for help.</p>
      <div class="desktop-hide">
        <div class="nav-icon">&#9742;</div>
        <span>Ask</span>
      </div>
    </div>
    <div class="drawer-7">
      <p>What are my friends talking about?</p>
      <p>(Follow conversations about the future of education)</p>
      <div class="desktop-hide">
        <div class="nav-icon">&#10040;</div>
        <span>Engage</span>
      </div>
    </div>
  </div>
  <div class="right-container">
    <h1>Hello, Your Name!</h1>
    <p (click)="openHelpFriend()">That's all I've got for now...</p>
    <p style="margin-top: 220px;">Go back to <a routerLink="/login">login page.</a></p>
    <!-- <div class="bottom-nav">
      <div class="teacher-store-btn btn">
        <a href="https://codeastrology.myshopify.com/collections/zodiac-hoodies">Teacher's Store</a>
      </div>
    </div> -->
  </div>
</div>
<app-help-a-friend ng-show="showHelpAFriend"></app-help-a-friend>
<app-ask-for-help></app-ask-for-help>
<app-conversations></app-conversations>

