import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit(): void {
    let menu = document.querySelector('.navbar-right');
    if(window.screen.width < 900) {
      menu.classList.add('hide');
    }
  }

  navigateHome() {
    this.router.navigateByUrl('/');
  }

  navigateToRegister() {
    this.router.navigateByUrl('/register');
  }

  navigateToLogin() {
    this.router.navigateByUrl('/login');
  }

  toggleMenu() {
    let menu = document.querySelector('.navbar-right');
    menu.classList.contains('hide') ? menu.classList.remove('hide') : menu.classList.add('hide');

  }
}
