<!-- <app-navigation></app-navigation> -->

<div class="login-page">
  <h1 class="welcome">Welcome to the <br><span>Teacher's Lobby</span></h1>
  <p>Teaching is hard. Let's support one another :) </p>
  <div class="login-container">
    <form>
      <input type="text" id="username" name="username" placeholder="username"><br>
      <input type="text" id="password" name="password" placeholder="password"><br>
    </form>
    <!-- <button class="login-btn" routerLink="/home" routerLinkActive="active" (click)="checkUserCredentials()">Sign In</button> -->
    <button class="login-btn" (click)="checkUserCredentials()">Sign In</button>

    <p>Don't have an account? Register <a routerLink="/register" routerLinkActive="active">here.</a></p>
  </div>
</div>
