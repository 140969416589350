<!-- <app-navigation></app-navigation> -->
<div class="homepage-container">
  <div class="hp-hero-banner">
    <img src="../../assets/images/tlobby-hp-hero.png" alt="Teacher's Lobby Banner">
  </div>
  <div class="hp-hero-overlay"></div>
  <div class="hp-hero-copy">
    <h1>
      <span>Teachers helping teachers help our children...</span><br>
      <span>for the sake of our future.</span>
    </h1>
  </div>
  <div class="desktop-only">
    <div class="join-us">
      <h3> Join Us </h3>
    </div>
    <div class="btn-wrapper">
      <div class="register-btn">
        <a href="/register" class="register">Create a free account</a>
      </div>
      <div class="login-btn">
        <a href="/login" class="login">Sign In</a>
      </div>
    </div>
  </div>
  <app-login-page></app-login-page>
</div>


