import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestEndpoint } from 'src/environments/environment';
import { UserData } from './user.model';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  userData: UserData[];

  constructor(private http: HttpClient) { }

  fetchUserData(){
    return this.http.get<UserData[]>('/assets/data/users.json').pipe(
      map(data => {
        const usersArray: Array<any> = [];
        for(let id in data) {
          if(data.hasOwnProperty(id)) {
            usersArray.push(data[id]);
          }
        }
        return usersArray;
      })
    )
  }
}
