<!-- <app-navigation></app-navigation> -->
<div class="blog-container">
  <h1 class="blog-header">Latest Thoughts</h1>
  <div class="blog-categories">
    <ul>
      <li class="blog-category"><a href="#">All</a></li>
      <li class="blog-category"><a href="#">Classroom Management</a></li>
      <li class="blog-category"><a href="#">Curriculum</a></li>
      <li class="blog-category"><a href="#">Technology</a></li>
    </ul>
  </div>
  <div class="blog-stories">
    <div class="blog-story-card"></div>
    <div class="blog-story-card"></div>
    <div class="blog-story-card"></div>
    <div class="blog-story-card"></div>
    <div class="blog-story-card"></div>
    <div class="blog-story-card"></div>
  </div>
</div>
