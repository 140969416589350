import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../user-data.service';
import { Users } from '../user.model';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  userData: Array<any>;
  userName: any;
  loginName: any;
  loginPW: any;

  constructor(private userDataService: UserDataService, private router: Router) { }

  ngOnInit(): void {
    this.displayUserData();
  }

  displayUserData() {
    this.userDataService.fetchUserData().subscribe({
      next: data => {
        this.userData = data[0];
        console.log(this.userData);
        console.log(this.userData[0].firstName);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
  }

  checkUserCredentials() {
    this.loginName = document.querySelector('#username');
    this.loginPW = document.querySelector('#password');
    console.log(this.loginName.value);
    console.log(this.userData[0].handleName);
    if(this.loginName.value == this.userData[0].handleName){
      console.log(this.userData[0].handleName);
    }

    for(let user of this.userData){
      // if(user.handleName == this.loginName.value && user.password == this.loginPW.value ) {
      if(user.handleName == this.loginName.value) {
        if(user.password == this.loginPW.value) {
          this.userName = user.firstName;
          // alert('Your username and password match!');
          this.router.navigateByUrl('/home');
        } else {
          alert('Incorrect password. Please try again!');
          break;
        }
      } else {
        alert('Incorrect username or password. Please try again!');
        break;
      }
      break;
    }

    console.log(this.loginName);
  }

  alertUserData() {
    this.userDataService.fetchUserData().subscribe({
      next: data => {
        this.userName = this.userData[0][0].firstName;
        alert('Hello ' + this.userName);

      }
    })
  }

}
