<!-- <p>registration works!</p> -->
<!-- <app-navigation></app-navigation> -->

<h2>Please take a moment to register below:</h2>
<form>
  <input type="text" id="firstName" name="firstName" placeholder="First Name"><br>
  <input type="text" id="lastName" name="lastName" placeholder="Last Name"><br>
  <input type="text" id="handleName" name="handleName" placeholder="What should we call you?"><br>
  <input type="text" id="gradeLevel" name="gradeLevel" placeholder="What grade do you teach?"><br>
  <input type="text" id="email" name="email" placeholder="School Email"><br>
  <button>Register</button>
</form>
<p>Go back to <a routerLink="/login">login page.</a></p>
