import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginPageComponent } from './login-page/login-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { RegistrationComponent } from './registration/registration.component';
import { HelpAFriendComponent } from './help-a-friend/help-a-friend.component';
import { AskForHelpComponent } from './ask-for-help/ask-for-help.component';
import { ConversationsComponent } from './conversations/conversations.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NavigationComponent } from './navigation/navigation.component';
import { AboutComponent } from './about/about.component';
import { MissionComponent } from './mission/mission.component';
import { ParticipateComponent } from './participate/participate.component';
import { BlogComponent } from './blog/blog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomePageComponent,
    RegistrationComponent,
    HelpAFriendComponent,
    AskForHelpComponent,
    ConversationsComponent,
    LandingPageComponent,
    NavigationComponent,
    AboutComponent,
    MissionComponent,
    ParticipateComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
